import RestManager from "./RestManager"
import Constants from "./Constants"
import LocalStorageManager from "./LocalStorageManager"

export const isBrowser = typeof window !== "undefined"

export const handleLogin = async ({ username, password }) => {
  if (!isBrowser) {
    return false
  }

  const response = await RestManager.post(Constants.AUTHENTICATION_ENDPOINT,
    {
      "username": username,
      "password": password,
    })
    .then(response => {
        LocalStorageManager.setAll(response.data)
        return true
      },
    ).catch(error => {
      console.log(error)
      return false
    },
  )
  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) {
    return false
  }

  const userName = LocalStorageManager.getKey("username")

  return userName !== undefined
}

export const logout = callback => {
  if (!isBrowser) {
    return
  }

  LocalStorageManager.clear()
  callback()
}