import React from "react"
import { Container, Header, Table, Divider, List, Icon } from "semantic-ui-react"
import {Link} from "gatsby";

const Footer = () => (
  <Container fluid style={{
      backgroundColor: "#212529",
      padding: "1em 5em 1em 5em",

      marginTop: "5em",
    }}
  >
    <Table columns={4} style={{backgroundColor: 'transparent', color: "#DFDFDF"}}>
        <Table.Header>
            <Table.Row textAlign={"center"}>
                <Table.Cell>
                    FIRENZE FIERA
                    <Divider/>
                </Table.Cell>
                <Table.Cell>
                    MENU
                    <Divider/>
                </Table.Cell>
                <Table.Cell>
                    MINISITI
                    <Divider/>
                </Table.Cell>
                <Table.Cell>
                    QUICK ACCESS
                    <Divider/>
                </Table.Cell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <Table.Row verticalAlign={"top"}>
                <Table.Cell>
                    <List>
                        <List.Item>
                            copyright 2016 Firenze Fiera CCIAA di Firenze
                        </List.Item>
                        <List.Item>
                            P.I. 04933280481
                        </List.Item>
                        <List.Item>
                            Capitale versato: € 21.778.035,84
                        </List.Item>
                    </List>
                </Table.Cell>
                <Table.Cell>
                    <List bulleted inverted>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/profilo-azienda/"}>
                                AZIENDA
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/le-nostre-strutture/"}>
                                LE NOSTRE STRUTTURE
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/eventi/"}>
                                EVENTI
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/territorio/"}>
                                TERRITORIO
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/news/"}>
                                NEWS
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://corporate.firenzefiera.it/"}>
                                CORPORATE
                            </a>
                        </List.Item>
                    </List>
                </Table.Cell>
                <Table.Cell>
                    <List bulleted inverted>
                        <List.Item>
                            <a href={"http://congresscenter.firenzefiera.it"}>
                                CONGRESS CENTER
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://exhibitioncenter.firenzefiera.it"}>
                                EXHIBITION CENTER
                            </a>
                        </List.Item>
                    </List>
                </Table.Cell>
                <Table.Cell>
                    <List inverted>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/contatti/"}>
                                Contattaci
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/lavora-con-noi/"}>
                                Lavora con noi
                            </a>
                        </List.Item>
                        <List.Item>
                            <a href={"http://www.firenzefiera.it/privacy/"}>
                                Privacy
                            </a>
                        </List.Item>
                    </List>
                </Table.Cell>
            </Table.Row>
            <Table.Row textAlign={"center"}>
                <Table.Cell colSpan={4} >
                    <a href={"https://twitter.com/firenze_fiera"}>
                        <Icon name={"twitter"} size={"big"}/>
                    </a>
                    <a href={"https://www.facebook.com/firenzefieraofficial"}>
                        <Icon name={"facebook f"} size={"big"}/>
                    </a>
                </Table.Cell>
            </Table.Row>

        </Table.Body>
    </Table>
  </Container>
)

export default Footer
